<template>
    <div class="min-h-screen flex-1 mt-1 bg-gray-100 px-4 py-3 items-center">
        <div class="bg-white w-full rounded-lg shadow pb-20">
            <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-5">
                <div class="flex w-full">
                   <button
            :class="$colors.primary"
            class="rounded-full flex justify-center items-center w-8 h-8 mr-3"
            @click="RouterBack()"
          >
            <i class="material-icons">chevron_left</i>
          </button>
                    <div class="text-xl font-bold text-green-700   ">{{$t('expedition_details')}}</div>
                </div>
            </div>
            <div id="detail">
                <div class="flex pr-5 pl-5 space-x-3">
                    <div class="flex justify-between shadow w-full h-auto p-4 my-1 rounded-lg border-gray-100">
                        <div class="flex w-full">
                            <span class="rounded-full h-12 w-12 bg-gray-100">
                                <i class="material-icons p-3 text-green-400 ">list_alt</i>
                            </span>
                            <div class="ml-5 w-full space-y-2">
                                <div class="text-base mt-2 mb-2 font-semibold text-gray-700">{{$t('detail')}}</div>
                                <div class="text-sm text-gray-600">
                                   {{$t('seller')}} :
                                    <span v-if="formData.seller" class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700">
                                        {{ formData.seller.username }}
                                    </span>
                                </div>
                                <div class="text-sm text-gray-600">
                                   {{$t('number_of_packages')}} :
                                    <span
                                        class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700">
                                        {{ formData.numberOfPackages }}
                                    </span>
                                </div>
                                 <div class="text-sm text-gray-600">
                                  {{$t('weight')}} :
                                    <span
                                        class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700">
                                        {{ formData.weight }}
                                    </span>
                                </div>
                                <div class="text-sm text-gray-600">
                                    {{$t('from')}} :
                                    <span
                                        class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700">
                                        {{ $f.getCountryNameByCode(options,formData.country) }}
                                    </span>
                                </div>
                                 <div class="text-sm text-gray-600">
                                   {{$t('to')}} :
                                    <span
                                        class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700">
                                        {{ $f.getCountryNameByCode(options,formData.country_to) }}
                                    </span>
                                </div>
                              
                                
                            </div>
                        </div>
                    </div>
                    <!--******************** Customer information ********************-->
                    <div class="flex justify-between shadow w-full h-auto p-4 my-1 rounded-lg border-gray-100">
                        <div class="flex w-full">
                            <div class="ml-5 w-full">
                              <div  class="text-sm text-gray-600 mb-2">
                                     {{$t('expedition_date')}} :
                                    <span
                                        class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700">
                                        {{ formattedDateExpeded }} 
                                    </span>
                                </div>
                                <div v-if="formattedDateReception!='Invalid date'" class="text-sm text-gray-600 mb-2">
                                   {{$t('reception_date')}} :
                                    <span
                                        class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700">
                                        {{ formattedDateReception }} 
                                    </span>
                                </div>
                                 <div class="text-sm text-gray-600 mb-2">
                                  {{$t('transport_mode')}} :
                                    <span
                                        class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700">
                                        {{ formData.transportMode }} 
                                    </span>
                                </div>
                                <div class="text-sm text-gray-600 ">
                                   {{$t('transport_fees')}} :
                                    <span
                                        class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700">
                                      <sup> {{currency}}</sup>  {{ formData.fees }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex pr-5 pl-5 mt-6">
                    <div class="flex justify-between w-full h-auto p-4 rounded-lg border-gray-100">
                        <div class="flex w-full">
                            <span class="rounded-full h-12 w-12 bg-gray-100">
                                <i class="material-icons p-3 text-green-400 ">local_mall</i>
                            </span>
                            <div class="ml-1 w-full p-2">
                                <div class="text-base font-semibold text-gray-700">
                                    {{$t('products_expedition')}}
                                </div>

                                <div class="w-full mt-4">
                                  <table class="min-w-full w-full">
                                        <thead class="text-white">
                                            <tr class="bg-green-400  flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                                                <th class="p-2 justify-center">{{$t('image')}}</th>
                                                <th class="p-2 justify-center">{{$t('name')}}</th>
                                                <th class="p-2 justify-center">{{$t('commodity_value')}}</th>
                                                <th class="p-2 justify-center">{{$t('quantity_sent')}}</th>
                                                <th class="p-2 justify-center">{{$t('quantity_received')}}</th>
                                            </tr>
                                        </thead>
                                        <tbody class="flex-1 sm:flex-none">
                                            <tr v-for="it in formData.details" :key="it._id" class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                                                <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2">
                                                    <div> <img :alt="it.product.name" :src="it.product.picture||'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'" class="h-16 w-16 rounded-md mx-auto" /> </div>
                                                </td>
                                                <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"><b>{{ it.product.name }}</b></td>
                                                <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"><b>{{ it.merchandise_value }} <sup> {{currency}}</sup> </b></td>
                                                <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                    {{ it.quantity.sent }}
                                                </td>
                                                <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                    <table class="min-w-full">
                                                        <thead>
                                                        <tr>
                                                            <th class="text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500  tracking-wider">{{$t('ok_good')}}</th>
                                                            <th class="text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500  tracking-wider">{{$t('defective')}}</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody class="bg-white"> 
                                                            <tr> 
                                                            <td class="px-6 py-4 border whitespace-no-wrap">{{ it.quantity.received }}</td>
                                                            <td class="px-6 py-4 border whitespace-no-wrap"> {{ it.quantity.defective }}</td>    
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
//import button from "../../components/~/button.vue";
export default {
        data() {
            return {
                formData: {
                    carrier: {},
                    details: [],
                },
                show_modal: false,
                content: [],
                currentUser: {},
                currency:'',
                options:this.$countries,
            };
        },
        async mounted() {
            if (this.$route.params.id) {
                const data = await this.$server.get("expeditions", {
                    id: this.$route.params.id,
                });
                this.formData = data.content;
            }
            await this.getUser();
            await this.getwharhouse();
        },
        computed: {
            formattedDateExpeded() {
                return this.$moment(this.formData.date).format('yyyy-MM-DD HH:mm');
            },
             formattedDateReception() {
                return this.$moment(this.formData.arrivalDate).format('yyyy-MM-DD HH:mm');
            },
            
        },
        methods: {
            RouterBack: function() {
                this.$router.back();
            },
            async getwharhouse(){
                const resW = await this.$server.get("warehouses", { country: this.formData.country_to}); 
                if(resW.content) return  this.currency=resW.content.currency;
            },
            async getUser() {
                const res = await this.$me;
                this.currentUser = res.content;
            },
            /*******************************************Modal (View) update Chapping address and Status Order ************************************/
            ShowPopUpInformation(index) {
                let modal_id = "modal_" + index;
                this.$refs[modal_id][0].showModal();
            },
            async closePopUpInformation(index) {
                let modal_id = "modal_" + index;
                this.$refs[modal_id][0].close();
            },

            /**************************Fin Modal ****************************** */
        },
    };
</script>

<style>
    /* animation for vue transition tag */
    dialog[open] {
        animation: appear 0.15s cubic-bezier(0, 1.8, 1, 1.8);
    }

    dialog::backdrop {
        background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(54, 54, 54, 0.5));
        backdrop-filter: blur(3px);
    }

    @keyframes appear {
        from {
            opacity: 0;
            transform: translateX(-3rem);
        }

        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
</style>